<div [formGroup]="form"  class="form">
    <button mat-icon-button type="button"
    [ngStyle]="control.style || false"
    id="{{getControlId()}}"
    [matTooltip]="control.tooltipMessage ? ((control.tooltipTranslatePrefix + '.' + control.tooltipMessage | translate) || '') : ''"
    [matTooltipPosition]="control.tooltipPosition || 'before'"
    [matBadge]="showBadgeIconBasedContext()"
    matBadgeSize="small" 
    class="{{control.className}} || button-details" 
    (click)="callButtonFunction(controlParent)">
    <mat-icon class="{{control.classIconName}} || iconDetails m-r-10">{{control.iconName || 'read_more'}} </mat-icon>   
    <span>{{control.noName ? '' : ((control.labelTranslatePrefix || 'global') + '.' + getName() | translate)}}</span> 
    </button>
</div>