import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import * as jsondiffpatch from 'jsondiffpatch';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as moment from 'moment';
import { ODataQuery } from '../global';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private validDateFormats = ['YYYY-MM-DDTHH:mm:SS'];

  constructor(
    private translateService: IntlService,
    private translate:TranslateService
  ) { }

  convertObjectStringsToDates(object: any, removeTime = true): any {
    if (!object || !(object instanceof Object)) {
      return;
    }
    for (const prop in object) {
      if (Object.prototype.hasOwnProperty.call(object, prop)) {
        // if (object instanceof Array) {
        //   for (const item of object) {
        //     this.convertStringsToDates(item);
        //   }
        // }
        if (object[prop] instanceof Object) {
          this.convertObjectStringsToDates(object[prop]);
        }
        if (typeof object[prop] === 'string' && this.stringIsValidDate(object[prop])) {
          object[prop] = new Date(object[prop]);
        }
      }
    }
  }

  convertInputToDate(value: string): Date {
    return moment(value, 'DD/MM/YYYY').toDate();
  }

  stringIsValidDate(value: string): boolean {
    return moment(value, 'YYYY-MM-DDTHH:mm:SS', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:ss.SSS', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:ss.S', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:ss.SS', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:SS.SSZ', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:SS.SZ', true).isValid()
    || moment(value, 'YYYY-MM-DDTHH:mm:SS.SSSZ', true).isValid();
  }

  getDistanceFromLatLonInKm(lat1:number, lng1:number, lat2:number, lng2:number) {
    var R = 6378; 
    var dLat = this.deg2rad(lat2-lat1);  
    var dLon = this.deg2rad(lng2-lng1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; 
    return d;
  }

  deg2rad(deg:any) {
    return deg * (Math.PI/180)
  }

  groupBy(xs:any, key:any){//prend une liste et fait un groupBy selon la key
    return xs.reduce(function(rv:any, x:any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  convertMeterToFoot(meters:number){
    return (meters * 3.28084);
  }

  convertCentimetersToFoot(centimeters:number){
    return (centimeters / 30.48);
  }

  convertInchToFoot(inches:number){
    return (inches / 12);
  }

  generateUniqueID(size = 8): any{
    const random = Math.random() + 1;
    return (Date.now() * random).toString().substring(5,size);
  }

  parseSpecialCharactersFormBackEndSearch(value : string){
    value = value.replace(/&/g,'%26');
    value = value.replace(/#/g,'%23');
    value = value.replace(/'/g,'\'\'');
    return value;
  }

  isLatLngWithinPolygon(lat:number, lng:number, polygon:any) {
    var inside = false;

    // Iterate over each point in the polygon and check if the point is inside
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      var lat_i = polygon[i][0], lng_i = polygon[i][1];
      var lat_j = polygon[j][0], lng_j = polygon[j][1];

      // Check if the point is on the boundary of the polygon
      if ((lat === lat_i && lng === lng_i) || (lat === lat_j && lng === lng_j)) {
        return true;
      }
  
      // Check if the point is inside the polygon
      if ((lng_i > lng) !== (lng_j > lng) && lat < ((lat_j - lat_i) * (lng - lng_i)) / (lng_j - lng_i) + lat_i) {
        inside = !inside;
      }
    }
  
    return inside;
  }

  convertStringsToDates(object: object, removeTime = false): any {
    if (!object || !(object instanceof Object)) {
      return;
    }
    if (object instanceof Array) {
      for (const item of object) {
        this.convertStringsToDates(item, removeTime);
      }
    }
    for (const key of Object.keys(object)) {
      const value = (object as any)[key];
      if (value instanceof Array) {
        for (const item of value) {
          this.convertStringsToDates(item, removeTime);
        }
      }
      if (value instanceof Object) {
        this.convertStringsToDates(value, removeTime);
      }
      if (typeof value === 'string') {
        const dateFormat = 'YYYY-MM-DD';
        const splittedDate = value.split(/[-T]/);
        if (!isNaN(splittedDate[2] as any) && splittedDate[2] !== '' && !isNaN(splittedDate[1] as any) && splittedDate[1] !== '' 
        && !isNaN(splittedDate[0] as any) && splittedDate[0] !== '') {
          const date = moment(`${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`, dateFormat);
          if (date.isValid()) {
            if (removeTime) {
              (object as any)[key] = new Date(new Date(value).toDateString());
            } else {
              (object as any)[key] = new Date(value);
            }
          }
        }
      }
    }
  }

}
export function createODataQuery(query: ODataQuery | null, action?: string): string {
  let oDataQuery = '';
  if (query && query.filters.length > 0 && query.filters[0].filter !== '') {
    oDataQuery = '$filter=';
    for (let i = 0; i < query.filters.length; i++) {
      const element = query.filters[i];
      element.parameters.forEach(p => {
        element.filter = element?.filter?.replace('#', p);
      });
      oDataQuery = oDataQuery + (i > 0 && element.filter !== ''  ? ' and ' : '') + element.filter;
    }
  }
  return action ? `${action}?${oDataQuery}` : '?'+oDataQuery;
}

export function base64ToFile(data: string, mime: string, fileName: string): File {
  const bstr = atob(data);
  let len = bstr.length;
  const u8arr = new Uint8Array(len);
  while (len--){
    u8arr[len] = bstr.charCodeAt(len);
  }
  return new File([u8arr], fileName, {type: mime});
}

export function base64ToArrayBuffer(base64: string): Uint8Array {
  
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}


export function getDefaultBool(out: boolean, item: any): boolean {
  return item === undefined ? out : item;
}



export function isPhoneValid(control: any): any {
	return new Promise((resolve) => {
		if (control?.value) {
      if (parsePhoneNumber(control?.value?.toString(), 'CA').isValid()) {
        resolve(null)
      } else {
        resolve ({'invalidValue': ''})      
      }
    }else {
      resolve (null);
    }
	});
}

export function createDiffPatcher(propertiesToIgnore: string[] = []): jsondiffpatch.DiffPatcher {
  return jsondiffpatch.create({
    propertyFilter: (name: any, context: any) => {
      for (let index = 0; index < propertiesToIgnore.length; index++) {
        const item = propertiesToIgnore[index];
        const target = item.split('.');
        if (target.length > 1) {
          if(target[1] === name && target[0] === context?.parent?.childName) {
            return false;
          } 
        } else {
           if (target[0] === name) {
            return false;
           }
        }
      }
      return true;
    },
    objectHash: function(obj: any, index: any) {
      // to compare, try to find an id property, otherwise just use the index in the array
      return obj.name || obj.id || obj._id || '$$index:' + index;
    }
  });
}


export function removeProperty(object: object, propNames: string[]): any {
  if (!object || !(object instanceof Object)) {
    return;
  }
  if (object instanceof Array) {
    for (const item of object) {
      removeProperty(item, propNames);
    }
  }
  for (const key of Object.keys(object)) {
    const value = (object as any)[key];
    if (value instanceof Array) {
      for (const item of value) {
        removeProperty(item, propNames);
      }
    }
    if (value instanceof Object) {
      removeProperty(value, propNames);
    }
    if (propNames.includes(key)) {
      delete (object as any)[key];
    }
  }
}


