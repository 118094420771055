import { TranslateService } from '@ngx-translate/core';
import { Injectable, NgZone } from '@angular/core';
import { ReactiveFormConfig,  } from '@rxweb/reactive-form-validators';
import { TinDialogComponent } from '../components/tin-dialog/tin-dialog.component';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogEntityDetailsWithComponent, DialogEntityDetailsWithForm, DialogEntityManager, DialogSearch, SetupSearch } from '../global';
import { TinDialogEntityManagerComponent } from '../components/tin-dialog-entity-manager/tin-dialog-entity-manager.component';
import { TinDialogEntityDetailsComponent } from '../components/tin-dialog-entity-details/tin-dialog-entity-details.component';
import { TinDialogAnimationService } from '../components/tin-dialog/tin-dialog-animation.service';

@Injectable()

export class TinMessageService {
  constructor(
    public dialog: TinDialogAnimationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private zone: NgZone
  ){
    ReactiveFormConfig.set({
      validationMessage: {
        required: this.translate.instant('frontEndValidation.required'),
        maxLength: this.translate.instant('frontEndValidation.maxLength'),
        minLength: this.translate.instant('frontEndValidation.minLength'),
        compare: this.translate.instant('frontEndValidation.compare'),
        alpha: this.translate.instant('frontEndValidation.alpha'),
        pattern: this.translate.instant('frontEndValidation.pattern'),
        requiredTrue: this.translate.instant('frontEndValidation.requiredTrue'),
        email: this.translate.instant('frontEndValidation.email'),
        patternPassword: this.translate.instant('frontEndValidation.patternPassword'),
        aucunJourSelectione: this.translate.instant('horaire.errors.aucunJourSelectione'),
        marchandiseRepartitionQteOver: this.translate.instant('demandeCueillette.errors.marchandiseRepartitionQteOver'),
        marchandiseRepartitionQteUnder: this.translate.instant('demandeCueillette.errors.marchandiseRepartitionQteUnder'),
        minNumber:this.translate.instant('frontEndValidation.minNumber'),
        maxNumber:this.translate.instant('frontEndValidation.maxNumber'),
        inputMask:this.translate.instant('frontEndValidation.mask'),
        minDate:this.translate.instant('frontEndValidation.minDate'),
        invalidValue:this.translate.instant('frontEndValidation.mask')
      },
      reactiveForm: {errorMessageBindingStrategy: 1}
    });
  }
  showCustomMessage(message: string, duration?: number): void{
    this.snackBar.open(this.translate.instant(message), undefined, {
      panelClass: ['bg-success'],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: duration || 3000
    });
  }

  showCustomErrorValidationMessage(message: string): void{
    this.snackBar.open(this.translate.instant(message), this.translate.instant('global.ok'));
  }

  showErrorMessage(message: string, duration?: number): void{
    this.zone.run(() => { //zone / Empêche le message d'apparaître en haut de l'écran puis en bas lorsqu'il est appelé sur certains écrans.
      this.snackBar.open(this.translate.instant(message), this.translate.instant('global.ok'),{
        duration: duration,
        panelClass: ['bg-danger'],
      });
    });
  }

  showGeneralErrorMessage(): void{
    this.snackBar.open(this.translate.instant('error.general'), this.translate.instant('global.close'), {
      panelClass: ['bg-danger'],
    });
  }

  async showUnauthorizedMessage(): Promise<void>{
    const msg = await this.translate.get('error.nonAutorise').toPromise();
    const buttonText = await this.translate.get('global.close').toPromise();
    this.snackBar.open(msg, buttonText, {
      panelClass: ['bg-danger'],
    });
  }
  showValidationMessage(code: number): void {
    const title = this.translate.instant('backEndValidationErrors.prefix');
    this.snackBar.open(`${title} ${code}. ` + this.translate.instant(`backEndValidationErrors.${code}`), this.translate.instant('global.close'), {
      duration: 0
    });
  }


  openDialogConfirmation(title: string, message: string, buttonChangesVisible = false, notDeleting = false, buttonYesSave = false, 
     showTextArea = false, textAreaTitle?:string, textareaMaxLength?:number,
     width = '33%', height = '25vh', position = {}, pickupSaving = false): Observable<any>{
    const buttonVisible = (buttonChangesVisible || notDeleting || pickupSaving) ? false : true;
    const dialogRef = this.dialog.open(TinDialogComponent, {
      data: {
        title,
        message,
        buttonVisible,
        buttonChangesVisible,
        notDeleting,
        buttonYesSave,
        showTextArea,
        textAreaTitle,
        textareaMaxLength,
        pickupSaving
      },
      width: width,
      height: height,
      panelClass: 'dialog-container',
      position:position
    });
    return new Observable((observer) => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  openDialogMessage(title: string, message: string, showButtonOk = false, isMessageHTML = false, 
    height = '56vh', width = '40%', maxWidth = '80vw'): Observable<any> {
    const buttonVisible = false;
    const dialogRef = this.dialog.open(TinDialogComponent, {
      data: {
        title: title,
        message,
        buttonVisible,
        buttonOk: showButtonOk,
        isHtml: isMessageHTML
      },
      width: width,
      height: height,
      maxWidth: maxWidth,
      panelClass: 'dialog-container',
    });
    return new Observable((observer) => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }
  
  openCustomDialogSearch<T>(config: DialogSearch<T>): Observable<any>{
    //Utilise la zone pour éviter les problèmes avec le selectAll de la grille de kendo 
    return this.zone.runOutsideAngular(() => {
      const width = config.width || '50%';
      const minHeight  = config.minHeight  ||  '40vh';
      const position  = config.position  || {};
      config.title = config.title ?  this.translate.instant(config.title) : this.translate.instant('global.searchAdvanced');
      config.selectedFilterList = config.selectedFilterList || config.filterList[0] as any;
      config.showButtonNew = config.showButtonNew || false;
      config.loadGridWithTopOptions = config.loadGridWithTopOptions === undefined ? true : config.loadGridWithTopOptions;
      config.permissionDelete = config.permissionDelete || [];
      config.permissionWrite = config.permissionWrite || [];
      
      const dialogRef = this.dialog.open(config.component, {
        minHeight,
        width,
        position,
        panelClass: 'dialog-container',
        data: {...config } as SetupSearch<T>
        });
      return new Observable((observer) => {
          dialogRef.afterClosed().subscribe(result => {
            observer.next(result);
            observer.complete();
          });
      });
    });
  }
  

  openCustomDialog(component: any, cWidth = '50%', cHeight = 'auto', cTop = '10px', data?: any, panelClass = ''): Observable<any>{
    const dialogRef = this.dialog.open(component, {
      position: {top: cTop},
      width: cWidth,
      height: cHeight,
      panelClass: panelClass,
      data: data
    });
    return new Observable((observer) => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  openCustomDialogEntityDetails(config: DialogEntityDetailsWithForm | DialogEntityDetailsWithComponent): Observable<any>{
    const width = config.width || '25%';
    const height  = config.height  || '110%';
    const position  = config.position  || {right: '0px', top: '65px'};
    const cfg = config as any;
    const dialogRef = this.dialog.open(cfg.component ?? TinDialogEntityDetailsComponent, {
         position,
         width,
         height,
         backdropClass: 'custom-dialog-details',
         animation: {
           to: 'left',
           incomingOptions: {keyframeAnimationOptions: {duration: 50}},
           outgoingOptions: {keyframeAnimationOptions: {duration: 50}}
          },
          data: {setupForm: cfg.setupForm, title: cfg.title, headerText: cfg.headerText, data:cfg?.data}
      });
    return new Observable((observer) => {
         dialogRef.afterClosed().subscribe(result => {
           observer.next(result);
           observer.complete();
           });
       });
  }

  openCustomDialogEntityManager(config: DialogEntityManager, hasAnimation = true): Observable<any>{
    const width = config.width || '45%';
    const height  = config.height  || '95%';
    const maxWidth = config.maxWidth  || '80vw';
    const position  = config.position  || {top: '0px'};
    const dialogRef = this.dialog.open(TinDialogEntityManagerComponent, {
      position,
      width,
      height,
      maxWidth,
      backdropClass: 'customDialogEntityManager',
      animation: hasAnimation ?  {
        to: 'bottom',
        incomingOptions: {keyframeAnimationOptions: {duration: 100}},
        outgoingOptions: {keyframeAnimationOptions: {duration: 100}}
       } : undefined,
       data: {id: 0, width, height, ...config}
    });
    dialogRef.disableClose = config.disableClose ?? false;
    (<any>dialogRef.componentInstance.data).id = dialogRef.id;
 return new Observable((observer) => {
      dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
        });
    });
  }

}

