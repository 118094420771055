import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LibAuthService } from 'ngx-tin-auth';
import { Observable, take } from 'rxjs';
import { CountryId, LangueId, OdataFilter } from 'src/app/shared/global';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { BusService } from 'src/app/shared/services/bus.service';
import { UserClient } from '../admin/user/model/UserClient.model';
import { UserClientService } from '../admin/user/service/user-client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientResolver implements Resolve<boolean>  {
  constructor(
    private userClientService: UserClientService,
    private notificationService: NotificationService,
    private auth: LibAuthService,
    private serviceBus: BusService,
    private traslateService: TranslateService
){}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return new Observable(observer => {
      this.auth.getUserName().then(username => {
        if (username) {
          this.userClientService.get(undefined, undefined, OdataFilter.ByUsername.replaceFilterParams([username]))
            .pipe(take(1))
            .subscribe((userClient: UserClient) => {
              if (userClient) {
                this.serviceBus.clientId = userClient.clientId;
                this.serviceBus.groupeClientId = userClient.client.groupeClientId || null;
                this.serviceBus.clientLanguageId = userClient.client.langueId ||  LangueId.French; 
                this.serviceBus.clientPaysId = userClient.client.paysId ||  CountryId.Canada;
              }
            });
        }
      });

      // this.notificationService.list('Notification/GetUnreadQuantity', null, true)
      // .pipe(take(1))
      // .subscribe (notification => {
      //   if (notification && (notification as any) > 0) {
      //     this.serviceBus.hasUnreadNotification = true;
      //   } else {
      //     this.serviceBus.hasUnreadNotification = false;
      //   }
      // });
  
      return this.traslateService.get('global.nom')
      .pipe(take(1))
      .subscribe((res: string) => {
        //Forcer à attendre le chargement des traductions ; Dans certains cas, la grille ne charge pas les titres traduits.
        observer.next(true);
        observer.complete();
      });

    }); 

  }
}
