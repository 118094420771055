import { FilterableSettings } from '@progress/kendo-angular-grid';
import { AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { ApiBaseService } from './services/api-base-service';

// Enums
export enum Module {
    Client = 'client',
    Employe = 'employe',
    Admin = 'admin',
    DemandeCueillette = 'cueillette-livraison',
    Dispatch = 'dispatch',
    Formation = 'formation'
}

export enum StorageKey {
    Module = 'module',
    SubModule = 'subModule',
    InitialRoute = 'initialRoute',
    CurrentLanguage = 'currentLanguage',
    isAuth = '0',
    HeaderContentFilter = 'headerContentFilter',
    HeaderContentPeriodFilter = 'headerContentPeriodFilter',
    InitialQueryParams = 'initialQueryParams',
    LastTimePrivateZoneLogin = 'lastTimePrivateZoneLogin'
}

export enum ActivityTypeCode {
    pin = 'PIN',
    unpin = 'UNPIN',
    cueillette = 'CUEILLETTE',
    livraison = 'LIVRAISON'
}

export enum Language {
    French = 'fr',
    English = 'en'
}

export enum LangueId {
    French = 2,
    English = 1
}

export enum PaysId {
    Canada = 1,
    US = 2
}

export enum ResponseConfirmation {
    Yes = 'yes',
    No = 'no',
    Ok = 'ok',
    SaveAndSend = 'saveAndSave',
    SaveForLater = 'saveForLater'
}
export enum UserProviders {
    ADFS = 'adfs',
    AWS = 'aws'
}

export enum PersonType {
    Client = 'client',
    Employee = 'employee'
}

export enum TypeReferenceDemandeCueillette{
    Hu = 'HU',
    Palseq = 'PALSEQ',
    Stop = 'STOP',
    Seal = 'SEAL'
}

export enum ParametreRegleRepartition{
    HeureLimite = 'heureLimiteCueillette',
    EspaceRemorque = 'espaceRemorqueUtilise',
    DimensionMarchandise = 'dimensionMarchandise',
    QuantiteTotalPalette = 'nbPaletteCueillette',
    PoidsTotal = 'poidsTotalCueillette',
    Urgent = 'nbHeureCueilletteUrgente',
    AfficherChauffeurConnecte = 'showConnectedDrivers',
    RefreshRateMap = 'refreshRateMap',
    RefreshRateRequest = 'refreshRateRequest'
}

export enum TypeVariable{
    String = 'varchar',
    Number = 'int',
    Boolean = 'boolean'
}

export enum IdTypeReferenceDemandeCueillette{
    Hu = 1,
    Palseq = 2,
    Stop = 4,
    Seal = 3
}

export enum IdCategorieEquipSpecification{
    Transport = 1,
    Cueillette = 2,
    Livraison = 3
}

export enum IdCategorieCodeDesc{
    Transport = 21,
    Cueillette = 20,
    Livraison = 19
}

export enum DemandeCueilletteStatutId{
    EnCreation = 1,
    EnApprobation = 2,
    Approuvée = 3,
    Assignée = 4,
    Refusee = 7,
    ApprouveAvecModifications = 8,
    HorsDispatch = 9,
    // AjouteeAHoraire = 85,
    // AssigneeShunter = 86,
    // AssigneeChauffeur = 87,
    // Annulee = 92,
    // Refusee = 93
}

export enum TypeHoraire{
    Ouverture = 1,
    Expedition = 2,
    ExpeditionHiver = 3
}

export enum EmplacementInventaire{
    EnRoute = 1,
    CourInterNord = 2,
    Rolland = 3,
    CTR = 4,
    CourClient = 5,
    Garage = 6,
    Entreposage = 7,
    Location = 8
}

export enum DemandeCueilletteServiceSourceId{
    Pickup = 1,
    Delivery = 2,
    PickupDelivery = 3
}

export enum GroupeContact{
    ReceptionMarchandise = 3
}

export enum UnitePoids{
    Kg = 7,
    Lbs = 6
}

export enum UniteMesureLongueur{
    Centimetre = 2,
    Pouce = 1,
    Pied = 5,
    Metre = 8
}

export enum UniteMarchandise{
    palette = 2
}

export enum DemandeCueilletteServiceId{
    Heated = 3,
    Tailgate = 6,
    DangerousGoods = 2
}

export enum EquipCapaciteLongueurId{
    Petit = 1
}

export enum Parametres{
    MaxValeurDeclaree = 5000,
    LongMaxAccessibilite = 65,
    MinValeurDeclaree = 0,
    KiloEnLivres = 2.2, 
    IdVilleMaitreMontreal = 8236
}

export enum MontrealTerritoire {
    MTLE = 21,
    MTLO = 20
}

export enum ClientCuNumberSpecial{
    CashCollect = 'C001'
}

export enum MessageAction {
  SidebarActionSelectItem = 'sidebarActionSelectItem',

  HeaderActionSearch = 'headerActionSearch',

  ListDetailOpenStateChanged = 'listDetailOpenStateChanged',

  GridSelectedItemId = 'gridSelectedItemId',
  GridLoadingFinished = 'gridLoadingFinished',
  GridSelectionChange = 'gridSelectionChange',

  DetailActionNew = 'detailActionNew',
  DetailActionSave = 'detailActionSave',
  DetailActionSaveAndClose = 'detailActionSaveAndClose',
  DetailActionCancel = 'detailActionCancel',
  DetailActionClose = 'detailActionClose',
  DetailActionDelete = 'detailActionDelete',
  DetailActionBackChangeRow = 'detailActionBackChangeRow',
  DetailActionFormIsDirtyChange = 'detailActionFormIsDirtyChange',
  DetailActionFormStatusChangeAfterSubmitted = 'detailActionFormStatusChangeAfterSubmitted',

  DialogActionFormIsDirtyChange = 'dialogActionFormIsDirtyChange',
  DialogActionFormOk = 'dialogActionFormOk',

  HeaderContentActionNew = 'headerContentActionNew',
  HeaderContentActionRefresh = 'headerContentActionRefresh',
  HeaderContentActionChangeFilterGrid = 'headerContentActionChangeFilterGrid',
  HeaderContentActionDisableFilters = 'headerContentActionDisableFilters',
  HeaderContentActionEnableFilters = 'headerContentActionEnableFilters',
  HeaderContentActionChangeFilter = 'headerContentActionChangeFilter',


  HeaderDetailActionExpand = 'headerDetailActionExpand',
  HeaderDetailActionShrink = 'headerDetailActionShrink',
  HeaderDetailActionNew = 'headerDetailActionNew',
  HeaderDetailActionDelete = 'headerDetailActionDelete',
  HeaderDetailActionSave = 'headerDetailActionSave',
  HeaderDetailActionSaveAndClose = 'headerDetailActionSaveAndClose',
  HeaderDetailActionCancel = 'headerDetailActionCancel',
  HeaderDetailActionEdit = 'headerDetailActionEdit',
  HeaderDetailActionClose = 'headerDetailActionClose',
  HeaderDetailActionShow = 'headerDetailActionShow',
  HeaderDetailActionShowCompleted = 'headerDetailActionShowCompleted',
  HeaderDetailActionChangeTitle = 'headerDetailActionChangeTitle',
  HeaderDetailConfigButtons = 'headerDetailConfigButtons',

  ManageActionButton = 'manageActionButton',
  ManageButtonMoreOptions = 'manageButtonMoreOptions',

  HeaderDialogActionExpand = 'headerDialogActionExpand',
  HeaderDialogActionShrink = 'headerDialogActionShrink',
  HeaderDialogActionOk = 'headerDialogActionOk',
  HeaderDialogActionCancel = 'headerDialogActionCancel',
  HeaderDialogActionDelete = 'headerDialogActionDelete',
  HeaderDialogActionClose = 'headerDialogActionClose',

  LayoutSizeChanged = 'layoutSizeChanged',

  FormOnSubmit = 'formOnSubmit',
  FormClientInfosPrev = 'formClientInfosPrev',
  FormClientInfosNext = 'formClientInfosNext',
  FormClientInfosCreateTransporteur = 'formClientInfosCreateTransporteur',
  FormClientInfoUpdateConsignataire = 'formClientInfoUpdateConsignataire',
  FormClientAddMarchandiseRepartitions = 'formClientAddMarchandiseRepartitions',
  FormClientMarchandiseLoadingStart = 'formClientMarchandiseLoadingStart',
  FormClientMarchandiseLoadingEnd = 'formClientMarchandiseLoadingEnd',
  FormClearClientDetails = 'formClearClientDetails',
  FormAddClientToRole = 'formAddClientToRole',

  PrintPDF = 'printPDF',
  SendMail = 'sendMail',

  DetailClientExpand = 'detailClientExpand',
  DetailClientCollapse = 'detailClientCollapse',

  LoadContacts = 'loadContacts',
  FormMainComponentActionLoad = "FormMainComponentActionLoad",
  LoadNewPickupWithAutofillClient = 'loadNewPickupWithAutofillClient',
  SendPreferenceToMarchandise = 'sendPreferenceToMarchandise',

  SetGridMarchandiseEditionFalse = 'setGGridMarchandiseEditionFalse',

  DemandeCueilletteRapportBol = 'rapportBol',
  DemandeCueilletteCopy = 'demandeCueilletteCopy',
  DemandeCueillettePostpone = 'demandeCueillettePostpone',
  SendNewToInfo = 'sendNewToInfo',
  OpenConditionsPickup = 'openConditionPickup',
  IsModuleClientAndNotInCreation = 'isModuleClientAndNotInCreation',
  DemandeCueilletteErrorBillTo = 'demandeCueilletteErrorBillTo',
  DemandeCueilletteTransportLabels = 'demandeCueilletteTransportLabels',
  DemandeCueilletteRetourApprouve = 'demandeCueilletteRetourApprouve',
  DemandeCueillettePickupDelete = 'DemandeCueillettePickupDelete',
  factureFilter = 'factureFilter',

  DispatchStatistics = 'statistics',
  DispatchBreakAssignation = 'breakAssignation',
  DispatchBreakSwitchAssignation = 'breakSwitchAssignation',
  DispatchBreakAssignationFromActivityList = 'breakAssignationFromActivityList',
  DispatchDragStart = 'dispatchDragStart',
  DispatchDragEnd = 'dispatchDragEnd',
  DispatchGoToAddress = 'dispatchGoToAddress',
  DispatchGotoTruckFromRequestCard = 'dispatchGoToTruckFromRequestCard',
  DispatchOpenSuggestions = 'dispatchOpenSuggestion',
  DispatchOpenDialogMarchandise = 'dispatchOpenDialogMarchandise',
  DispatchReassignRequest = 'dispatchReassignRequest',
  DispatchReassignSwitch = 'dispatchReassignSwitch',
  DispatchSeeAllTrucks = 'dispatchSeeAllTrucks',
  DispatchSettings = 'dispatchSettings',
  DispatchFilterApplied = 'dispatchFilterApplied',
  DispatchRemoveActiviteFromList = 'dispatchRemoveActiviteFromList',
  DispatchGotoTruckFromSwitchOrRollandCard = 'dispatchGotoTruckFromSwitchOrRollandCard',
  DispatchSaveSwitchFromDialog = 'dispatchSaveSwitchFromDialog',
  DispatchAssignRollandToDisconnectedDriver = 'dispatchAssignRollandDisconnectedDriver',
  DispatchBreakAssignRolland = 'dispatchBreakAssignRolland',
  DispatchSaveRollandFromDialog = 'dispatchSaveRollandFromDialog',
  DispatchOpenPickupFromActivity = 'dispatchOpenPickupFromActivity',

  AuditTrail = 'auditTrail',

  CleanMessages = 'clean' // utilisé uniquement pour ne pas répéter le dernier message dans subscription observable ;
}

export enum GridFilter {
    All = "{\"filters\":[],\"logic\":\"and\"}"
}

export enum CountryId{
    Canada = 1
}

export enum ProvinceId{
    Quebec = 1
}

export enum VehicleState{
    EnRoute = 3,
    Idle = 4
}

export enum Gender {
    Masculine = 'masculine',
    Feminine = 'feminine',
    Neuter = 'neuter'
}

export enum DepartementNotification{
    Dispatch = 9
}

export enum TypeNotification{
    Dispatch = 2,
    Global = 1
}

export enum SecurityGroup {
    TinERP = 'TinERP',
    Admin = 'Admin',
    ModuleAdminEcrire = 'Module-Admin_Ecrire',
    ModuleAdminLire = 'Module-Admin_Lire',
    ModuleEmployeEcrire = 'Module-Employe_Ecrire',
    ModuleEmployeLire = 'Module-Employe_Lire',
    ModuleClientEcrire = 'Module-Client_Ecrire',
    ModuleClientLire = 'Module-Client_Lire',
}

export enum PeriodEdge {
    Start = 'start',
    End = 'end'
}

export enum ReponseConfirmation {
    Yes = 'yes',
    No = 'no'
}
export enum ReponseDialogEntityManager {
    Ok = 'ok',
    Cancel = 'cancel',
    Delete = 'delete'
}

export enum InputMask {
    Telephone = "(999) 999-9999",
    Heure = "99:99",
    HeureMinute = "^([0-1][0-9]|[2][0-3]):([0-5][0-9])$",
    HeureMinuteSecond = "^([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])",
    DateTime = "dd/mm/yyyy HH:MM"
}

export enum DemandeCueilletteClientRole {
    Expediteur = 1,
    Consignataire = 2,
    CueilliChezTransporteur = 3,
    LivréChezTransporteur = 4,
    Payeur = 5
}

export enum PeriodeTempsCueillette{
    A = 5,
    Avant = 6,
    Apres = 7,
    Entre = 8
}

export enum FeuilleRouteActiviteType{
    Cueillette = 2,
    Livraison = 1
}

export enum StatutActivite{
    Done = 2,
    NotDone = 1,
    Canceled = 3
}

export enum SwitchStatut{
    Created = 1,
    Assigned = 2,
    Done = 3,
    Rolland = 4
}

export enum TypesUnitesDeMesure{
    Longueur = 'LONGUEUR',
    Volume = 'VOLUME',
    Poids = 'POIDS',
    Temps = 'TEMPS',
    Distance = 'DISTANCE',
}

export enum PDF{
    condition ='https://www.inter-nord.com/wp-content/uploads/2022/09/Condition_2022.pdf',
    conditionFr = 'https://www.inter-nord.com/wp-content/uploads/2024/02/ConditionsBOL_FR.pdf',
    conditionEn = 'https://www.inter-nord.com/wp-content/uploads/2024/02/ConditionsBOL_EN.pdf'
}

export enum CodeDescCategories{
    DemCueil_SelectionTransport = 'DemCueil_SelectionTransport',
    DemCueil_SelectionCueillette = 'DemCueil_SelectionCueillette',
    DemCueil_SelectionLivraison = 'DemCueil_SelectionLivraison',
    CodeTypeMarchandise = 'CodeTypeMarchandise',
}

export enum EquipementType{
    Porteur = 49,
    Remorque = 50,
    Tracteur = 54
}

export enum GroupeParametre{
    Repartition = 15,
}

export enum ServiceRequis{
    Pickup = 1,
    Delivery = 2,
    PickupAndDelivery = 3
}

export enum TypeActiviteSwitch{
    Drop = 1,
    PickupTrailer = 2,
    Switch = 3
}

export enum TypeActiviteDispatch{
    pickup = 'pickup',
    switch = 'switch',
    rolland = 'rolland'
}

export enum EquipementSpecificationId{
    Tailgate = 1,
    Chauffe = 5
}

export enum DialogActions {
    Ok = 'ok',
    Cancel = 'cancel',
    New = 'new',
    Save = 'save',
    Close = 'close'
}

export enum GridEdtionMode {
    None = 'none',
    InCell = 'inCell',
    InLine = 'inline'
}

export enum DemandeCueilletteServiceSourceCodes {
    Pickup = 1,
    Delivery = 2,
    PickupDelivery = 3
}

export enum ProfileClient{
    clientBase = 'Client base'
}

export enum TinMapAnimation {
    BOUNCE = 1,
    DROP = 2,
}

export enum AuditSource {
    ClientOperation = 1,
    ClientMyInformation = 2,
    ClientPickupRequest = 3,
    ClientPreference = 4,
    EmployeClientTransportInstallation = 5,
    EmployeDemandeCueilletteService = 6,
    EmployeRolland = 7,
    EmployeTerritoireRepartition = 8,
    EmployeAssignationRolland = 9,
    EmployeAssignationSwitch = 10,
    EmployeSwitch = 11,
    EmployeAssignationPickup = 12,
    RemorqueInventaire = 13,
    GestionUserClient = 14,
    GestionUserEmploye = 15
}

// Odata
export enum OdataFilter {
    ById = '?$filter=Id eq ',
    Search = '?searchKey=',
    ByUsername = '?$filter=Username eq \'#\'',
}



// Classes
export class HeaderAction {
    public IsNewButtonVisible!: boolean;
    public IsExpandButtonVisible!: boolean;
    public IsDeleteButtonVisible!: boolean;
    public IsSaveButtonVisible? = false;
    public IsSaveAndCloseButtonVisible? = false;
    public IsCancelButtonVisible? = false;
    public HeaderTitle!: string;
    public GridSelectedItemId: any;
    public GridSelectedItem: any;
    public showMoreButton?: boolean;
    public buttonMore?: Array<ButtonOptionMore>;
}

export class HeaderConfigButtons {
    public IsSaveButtonVisible? = false;
    public IsCancelButtonVisible? = false;
}


export class MessageBus {
    public MessageAction!: MessageAction;
    public MessageData: any;
}


// Interfaces
export interface FilterList {
    code?: string;
    text: string;
    value: CompositeFilterDescriptor;
}

export interface SetupTinChip {
    label: string;
    placeHolder: string;
    selectable: boolean;
    removable: boolean;
    separatorKeysCodes: number[];
    maxLength?: number;
    allItems: any;
  }

  
export interface SetupHeaderDetail {
    showCloseButton?:boolean;
    showExpandButton?: boolean;
    showDeleteButton?: boolean;
    showSaveButton?: boolean;
    showSaveAndCloseButton?: boolean;
    showCancelButton?: boolean;
    showMoreButton?: boolean;
    headerTitle?: string;
    saveButtonIsDisabled?: boolean;
    saveAndCloseButtonIsDisabled?: boolean
    cancelButtonIsDisabled?: boolean;
    showRefreshButton?: boolean,
    buttonMore?: Array<ButtonOptionMore>;
    height?:string;
  }

  export interface SetupHeaderDialog {
    showExpandButton?: boolean;
    showDeleteButton?: boolean;
    showOkButton?: boolean;
    showSaveAndCloseButton?: boolean;
    showCancelButton?: boolean;
    okButtonIsDisabled?: boolean,
    cancelButtonIsDisabled?: boolean,
    id?: string
  }

  export interface DialogEntity {
    title: string;
    titleSuffix?: string
    width?: string;
    height?: string;
    maxWidth?: string;
    position?: {};
  }

  export interface DialogEntityDetails extends DialogEntity  {
    headerText: string;
  }
  export interface DialogEntityDetailsWithForm extends DialogEntityDetails  {
    setupForm: any;
  }
  export interface DialogEntityDetailsWithComponent extends DialogEntityDetails  {
    component: any;
    data?:any;
  }
  
  export interface DialogEntityManager extends DialogEntity  {
    component: any;
    modelToInitForm: any;
    data?: any;
    showOkButton? : boolean;
    showSaveAndCloseButton? : boolean;
    showExpandButton? : boolean;
    showCancelButton?: boolean;
    showDeleteButton?: boolean;
    permissions?: BasePermissions;
    disableClose?: boolean;
  }
  export interface DialogEntityManagerExt extends DialogEntityManager  {
    id?: any
  }

  export interface GridDropdownColumnConfig {
    data: Observable<any>; // list of items
    textFieldName: string;
    valueFieldName: string;
}

  export interface GridSetupEdition {
    editionMode: GridEdtionMode;
    model: any;
    modelInstanceDefaultValues: any;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    showAddButton?: boolean;
    customEditButtonFunc?: (dataItem: any) => void;
    customAddButtonFunc?: () => void;
    toolBarTemplate?: any;
    withColumnActionButtons?: number;
}

export interface BasePermissions {
    write: string[];
    delete: string[];
}

export interface ButtonOptionMore {
    id: string;
    label: string;
    iconName: string;
    messageAction: string;
    permissions: string[];
}

export interface ManageHeaderButtons{
    showExpandButton:boolean;
    showDeleteButton:boolean;
    showMoreButton:boolean;
    showSaveAndCloseButton:boolean;    
    showSaveButton:boolean;
    showCancelButton:boolean;
}

export interface ListDataSource<T> {
    action: string,
    service: ApiBaseService<T>;
    forceReload?: boolean;
}

export interface SearchAfterClose {
    selection: any[];
    action: DialogActions;
}

export interface ODataQuery {
    filters: {
        filter: string,
        parameters: any[]
    }[];
}

export interface GridSetup {
    columns: GridColumnSetting[];
    columnsToFilter?: GridColumnSetting[];
    filterStyle?: FilterableSettings | null;
    edition?: GridSetupEdition;
    reorderConfig?:GridReorderConfig;
    pageable?: boolean;
    resizable?: boolean;
    sortable?:boolean;
    pageSize?: number;
    showMultiSelection?: boolean;
    multiSelectionColumnWidth?: number;
    validateBeforeEditing?: (args: any) => boolean | null;
    validateAfterEditing?: (args: any) => boolean | null;
    context?: any;
    groupable?: boolean;
    groupExpanded?: boolean;
    grouping?: GroupDescriptor[];
    aggregates?: AggregateDescriptor[];
    detailGrid?: GridDetail;
    height?: string;
    enableEndlessScrolling?: boolean;
    oDataFilter?: {
        enabled: boolean;
        notifyChanges: boolean;
        loadOnlyFirstPage?: boolean;
        qtyTotalRecords?: number;
        orderBy?: string;
    };
    useLoadWithTopOptions?: {
        options: number[];
        selectedOption: number;
        eventChange?: (option: number) => void;
    };
    pdf?: GridPdf;
    excel?: GridExport;
}
export interface GridExport {
    fileName?: string;
}
export interface GridPdf extends GridExport {
    allPages?: boolean;
    forcePageBreak?: string;
    keepTogether?: string;
    landscape?: boolean;
    margin?: GridPdfPageMargin;
    paperSize?: string;
    repeatHeaders?: boolean;
    scale?: number;
}
export interface GridPdfPageMargin {
    left?: number | string;
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
  }
export interface GridDetail {
    setup: GridSetup;
    dataSourceFieldName: string;
}
export interface GridSetupEdition {
    editionMode: GridEdtionMode;
    model: any;
    modelInstanceDefaultValues: any;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    showAddButton?: boolean;
    customNoteButtonFunc?: (dataItem: any) => void;
    customDocumentButtonFunc?: (dataItem: any) => void;
    customDocumentButtonIcon?:string;
    customDocumentButtonTitle?:string;
    customEditButtonFunc?: (dataItem: any) => void;
    customDeleteButtonFunc?: (dataItem: any) => void;
    customDownloadButtonFunc?: (dataItem: any) => void;
    customAddButtonFunc?: () => void;
    toolBarTemplate?: any;
    getQtyNoteBadge?: (dataItem: any) => number;
    getQtyDocumentBadge?: (dataItem: any) => number;
    withColumnActionButtons?: number;
}
export interface GridColumnSetting {
    field: string | null;
    title: string | null;
    format?: string;
    hidden?: boolean;
    dynamicFormat?: (dataItem: any) => string;
    dynamicValue?: (dataItem: any) => any;
    width?: number;
    editable?: boolean;
    type: 'text' | 'numeric' | 'boolean' | 'date' | 'lookup' | 'sparkline' | null;
    link?: string;
    dynamicLink?: (dataItem: any) => string;
    linkShowIcon?:(dataItem:any) => boolean;
    linkParameters?: object;
    maxLength?: number;
    gridCellTemplate?: any;
    gridEditTemplate?: any;
    gridGroupFooterTemplate?: any;
    gridFooterTemplate?: any;
    gridDropdownColumn?: GridDropdownColumnConfig | null;
    gridDropdownFilterMenu?: GridDropdownColumnConfig | null;
    numberMin?: number;
    numberMax?: number;
    numberMaxBasedAnotherColumn?: string;
    numberStep?: number;
    locked?: boolean;
    warningMessage?: (dataItem: any) => string;
    lookupColumn?: GridLookupColumnConfig;
    dynamicBadgeColor?: (dataItem: any) => string;
    dynamicIcon?: GridIconColumnConfig;
    translatePrefix?: string;
    inputMask?: string | InputMask;
    phone?:boolean | false;
    filterColumn?:boolean;
    autoCorrect?:boolean;
    isVisibleOnMobile?: boolean;
    sparklineConfig?:GridSparklineConfig;
}
export interface GridIconColumnConfig {
    iconName: string;
    funcShowIcon: (dataItem: any) => boolean;
    funcActionIcon: (dataItem: any) => void;
}
export interface GridDropdownColumnConfig {
    data: Observable<any>;
    textFieldName: string;
    valueFieldName: string;
}
export interface GridLookupColumnConfig {
    action: (formGroup: RxFormGroup) => Observable<any>;
    valueFieldName: string;
}
export interface GridReorderConfig{
    reorderable:boolean;
    reorderableWidth:number;
    reorderConditionFunction?:(args:any) => boolean;
}
export interface GridSparklineConfig{
    sparklineMax?:number;
    sparklineMin?:number;
    sparklineChartAreaSize?:string;
    sparklineColor?:string;
}

export interface TinMapSetup {
    height?: string;
    width?: string;
    showSearchBox?: boolean;
    options?: google.maps.MapOptions;
}
export interface TinMapView {
    position: TinMapPosition;
    zoomLevel: number;
}
export interface TinMapMarkerOptions{
    draggable?: boolean, 
    animation?: TinMapAnimation | undefined
}
export interface TinMapMarker{
    code?: string;
    name?: string;
    label?: TinMapLabel;
    title?: string;
    icon?: {url?: string};
    provinceCode?: string;
    isDeleted?: boolean;
    position?: TinMapPosition;
    options?: TinMapMarkerOptions;
    isSVGMarker?:boolean;
    showLabel?:boolean;
    symbol?:{path?:string, fillColor?:string, rotation?:number, scale?:number, fillOpacity?:number, strokeOpacity?:number, strokeColor?:string}
}
export interface TinMapPosition{
    lat: number;
    lng: number;
}
export interface TinMapLabel {
    text?: string, 
    fontSize?: string,
    fontWeight?:string
}
export interface TinMapAddress {
    address?: string;
    province?: string;
    city?: string;
    provinceCode?: string;
    country?: string;
    countryCode?: string;
    postalCode?: string;
    gpsPosition?: TinMapPosition;
    //**Certaines adresses capturées par Google ne sont que des prédictions et ne sont pas considérées comme des adresses exactes. */
    isReliable?: boolean; 
}

export interface FilterListSearch<T> {
    text: string;
    value: string;
    service: any;
    actionWithEndpoint: string;
    odataFilter: string;
    filterResultArray?: (value: T, index: number, array: T[]) => any;
  }
export interface SetupSearch<T> {
    gridSetup?: GridSetup;
    title?: string;
    gridFilter: any;
    filterList: FilterListSearch<T>[];
    selectedFilterList?: string;
    showButtonNew?: boolean;
    loadGridWithTopOptions?: boolean;
    width?: string;
    minHeight?: string;
    permissionWrite?: string[];
    permissionDelete?: string[];
}
export interface SearchAfterClose {
    selection: any[];
    action: DialogActions;
}
export interface DialogSearch<T> extends SetupSearch<T> {
    component: any;
    showMultiSelection?: boolean;
    position?: {};
}
export interface DynFormBaseConfig {
    ui: any;
    entityPropIdName?: string; 
    entityService?: any; 
    audit?: AuditDetailsBaseDynForm;
    isAlsoUsedAsDialog?: boolean;
    optionsActions?: {
      delete?: {
         endpoint?: string, cleanEntitiesProperties?: boolean, cleanEntitiesPropertiesException?: string[] , 
         deletePropInsteadClean?: boolean, propToDeleteBeforeDelete?: string[], action?:string
      },
      save?: {
        endpoint?: string, cleanEntitiesProperties?: boolean, cleanEntitiesPropertiesException?: string[], 
        removeUndefinedFields?: boolean, deletePropInsteadClean?: boolean, propToDeleteBeforeSave?: string[], action?:string
      }
    } 
  }
  
  export interface DialogAuditDetails {
    width?: string;
    height?: string;
    position?: {};
    auditTrailSourceId: number;
    auditTrailSourceIdToGet:number[];
    sourceId: string;
    translateContext?: string[];
    ignoreTranslation?: boolean;
    listAudit?: Observable<any>;
    disableAnimation?: boolean;
  }
  export interface AuditDetailsBaseDynForm {
    auditTrailSourceId: number;
    auditTrailSourceIdToGet:number[];
    propertiesToIgnore?: string[];
    propertiesToIgnoreRecursively?: string[];
    translateContext?: string[];
    ignoreTranslation?: boolean;
    disableAnimation?: boolean;
  }


//Const
export const ButtonMore = [
    {
        id:'-1', 
        iconName:'track_changes', 
        label: 'global.auditTrail',
        messageAction: MessageAction.AuditTrail,
        permissions: []
    },
]

export const GridTopOption = {
    Default: {
        options: [15, 50, 100, 150], 
        selectedOption: 15
    }
}

export const MONTH = [
    {id: 0, number: 1, abbreviatedName: 'Jan', name: 'January'},
    {id: 1, number: 2, abbreviatedName: 'Feb', name: 'February'},
    {id: 2, number: 3, abbreviatedName: 'Mar', name: 'March'},
    {id: 3, number: 4, abbreviatedName: 'Apr', name: 'April'},
    {id: 4, number: 5, abbreviatedName: 'May', name: 'May'},
    {id: 5, number: 6, abbreviatedName: 'Jun', name: 'June'},
    {id: 6, number: 7, abbreviatedName: 'Jul', name: 'July'},
    {id: 7, number: 8, abbreviatedName: 'Aug', name: 'August'},
    {id: 8, number: 9, abbreviatedName: 'Sep', name: 'September'},
    {id: 9, number: 10, abbreviatedName: 'Oct', name: 'October'},
    {id: 10, number: 11, abbreviatedName: 'Nov', name: 'November'},
    {id: 11, number: 12, abbreviatedName: 'Dec', name: 'December'}
    ];
