import { BusService } from '../shared/services/bus.service';
import { StorageKey, Module } from './../shared/global';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SecurityGroup } from '../shared/global';
import { TinMessageService } from '../shared/services/tin-message.service';
import { LibAuthService } from 'ngx-tin-auth';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private auth: LibAuthService,
    private messageService: TinMessageService,
    private serviceBus: BusService,
    private ngxPermissionsService: NgxPermissionsService
  ) { }



  public get isUnauthorizedUser(): boolean | null {
    const isAuth = sessionStorage.getItem(StorageKey.isAuth);
    if (isAuth === '1') {
      return false;
    } else if (isAuth === '0') {
      return true;
    } else {
      return null;
    }
  }
  async hasAccess(group: SecurityGroup): Promise<boolean> {
    const groups = await this.auth.getCognitoGroups();
    if (groups && group) {
      return groups.includes(group);
    } else {
      return false;
    }
  }

  async hasAccessModuleAdmin(): Promise<boolean> {
    try {
      const groups = await this.auth.getCognitoGroups();
      if (groups) {
        return groups.includes(SecurityGroup.TinERP) &&
        (groups.includes(SecurityGroup.Admin) || groups.includes(SecurityGroup.ModuleAdminLire) || groups.includes(SecurityGroup.ModuleAdminEcrire));
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  async hasAccessModuleEmploye(): Promise<boolean> {
    try {
      const groups = await this.auth.getCognitoGroups();
      if (groups) {
        return groups.includes(SecurityGroup.TinERP) &&
      (groups.includes(SecurityGroup.Admin) || groups.includes(SecurityGroup.ModuleEmployeEcrire) || groups.includes(SecurityGroup.ModuleEmployeLire));
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  async hasAccessModuleClient(): Promise<boolean> {
    try {
      const groups = await this.auth.getCognitoGroups();
      if (groups) {
        return groups.includes(SecurityGroup.TinERP) &&
      (groups.includes(SecurityGroup.Admin) || groups.includes(SecurityGroup.ModuleClientEcrire) || groups.includes(SecurityGroup.ModuleClientLire));
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public async getFirstAuthorizedModule(): Promise<Module | null> {
    const groups = await this.auth.getCognitoGroups();
    if (groups?.includes(SecurityGroup.TinERP)) {
      if (groups.includes(SecurityGroup.ModuleClientEcrire) || groups.includes(SecurityGroup.ModuleClientLire)) {
        return Module.Client;
      } else if (groups.includes(SecurityGroup.ModuleEmployeEcrire) || groups.includes(SecurityGroup.ModuleEmployeLire)) {
        return Module.Employe;
      } else if (groups.includes(SecurityGroup.Admin) || groups.includes(SecurityGroup.ModuleAdminEcrire) || groups.includes(SecurityGroup.ModuleAdminLire)) {
        return Module.Admin;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  unauthorizedUser(): void {
    sessionStorage.setItem(StorageKey.isAuth, '0');
    if (this.serviceBus.module === Module.Client) {
      this.messageService.showUnauthorizedMessage();
      setTimeout(() => {
        this.auth.signOut();
      }, 3000);
    } else {
      this.auth.signOut();
    }

  }
  async authorizedUser(): Promise<void> {
    sessionStorage.setItem(StorageKey.isAuth, '1');
    const authUser = {
      username: await this.auth.getUserName(),
      name: await this.auth.getUserFullName()
    };
    this.serviceBus.authenticatedUser = authUser;
  }

  async getPermissions(): Promise<string[]>{
    const groups = await this.auth.getCognitoGroups();
    return groups;
  }
  async hasPermissions(permission: string | string[]): Promise<boolean> {
    return await this.ngxPermissionsService.hasPermission(permission).then();
  }

  async loadPermissions(): Promise<void> {
    const groups = await this.getPermissions();
    this.ngxPermissionsService.loadPermissions(groups);
  }

  checkAccessPrivateZone() {
    if (this.serviceBus.checkUserPrivateZone && this.serviceBus.lastTimePrivateZoneLoginInMinutes >= environment.timeDelayPrivateZoneLoginInMinutes) {
      this.serviceBus.lastTimePrivateZoneLoginInMinutes = new Date();
      this.auth.signOut();
    }
  }

}
