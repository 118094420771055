import { PhonePipe } from './pipes/phone-pipe.pipe';
import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './directives/accordion';
import { MenuService } from './menu/menu.service';
import { NotificationComponent } from './notification/notification.component';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { LocalizedDatePipe } from './pipes/localizedDate.pipe';
import { SkeletonDatePipe } from './pipes/skeletonDate.pipe';
import { LocalizedNumberPipe } from './pipes/localizedNumber.pipe';
import { RouterModule } from '@angular/router';
import { DynamicFormsModule } from './dynamic-forms/dynamic-forms.module';
import { TinDialogEntityDetailsComponent } from './components/tin-dialog-entity-details/tin-dialog-entity-details.component';
import { ListDetailContentDirective } from './directives/list-detail-content.directive';
import { TinListDetailComponent } from './components/tin-list-detail/tin-list-detail.component';
import { TinGridComponent } from './components/tin-grid/tin-grid.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TinChipComponent } from './components/tin-chip/tin-chip.component';
import { HeaderContentComponent } from '../layout/header-content/header-content.component';
import { HeaderDialogComponent } from '../layout/header-dialog/header-dialog.component';
import { HeaderDetailComponent } from '../layout/header-detail/header-detail.component';
import { TinBadgeComponent } from './components/tin-badge/tin-badge.component';
import { TinDialogEntityManagerComponent } from './components/tin-dialog-entity-manager/tin-dialog-entity-manager.component';
import { TinDialogComponent } from './components/tin-dialog/tin-dialog.component';
import { TinMap } from './components/tin-map/tin-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TinSearchComponent } from './components/tin-search/tin-search.component';
import { HeaderDialogLightComponent } from '../layout/header-dialog-light/header-dialog-light.component';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { TinAuditComponent } from './components/tin-audit/tin-audit.component';
import { TinAuditDetailComponent } from './components/tin-audit/tin-audit-detail/tin-audit-detail.component';

@NgModule({
  declarations: [
    AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ListDetailContentDirective,
    Nl2brPipe, LocalizedDatePipe, SkeletonDatePipe, LocalizedNumberPipe, PhonePipe, OrderByPipe,
    HeaderContentComponent,
    HeaderDetailComponent,
    HeaderDialogComponent,
    HeaderDialogLightComponent,
    NotificationComponent,
    TinDialogComponent,
    TinDialogEntityDetailsComponent,
    TinDialogEntityManagerComponent,
    TinListDetailComponent,
    TinGridComponent,
    TinChipComponent,
    TinBadgeComponent,
    TinSearchComponent,
    TinMap,
    TinAuditComponent,
    TinAuditDetailComponent
  ],
  imports: [
    RouterModule,
    PdfViewerModule,
    DynamicFormsModule,
    NgxPermissionsModule.forChild(),
    GoogleMapsModule
  ],
  exports: [
    AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ListDetailContentDirective,
    Nl2brPipe, LocalizedDatePipe, SkeletonDatePipe, LocalizedNumberPipe, PhonePipe, OrderByPipe,
    HeaderContentComponent,
    HeaderDetailComponent,
    HeaderDialogComponent,
    HeaderDialogLightComponent,
    NotificationComponent,
    TinDialogComponent,
    TinDialogEntityDetailsComponent,
    TinDialogEntityManagerComponent,
    TinListDetailComponent,
    TinGridComponent,
    TinChipComponent,
    TinBadgeComponent,
    TinSearchComponent,
    PdfViewerModule,
    DynamicFormsModule,
    NgxPermissionsModule,
    GoogleMapsModule,
    TinMap,
    TinAuditComponent,
    TinAuditDetailComponent

  ],
  providers: [
    MenuService,
  ]
})
export class SharedModule {}

