<div [formGroup]="form" class="dyn-form-component" >
    <div class="input-group p-b-10">
        <mat-form-field appearance="outline"  [ngClass]="{'errorField': hasControlError}">
            <mat-label class="custom-mat-label">{{control.translatePrefixLabel + '.' + getName() | translate}}
                <span *ngIf = "formGroup?.invalid" class="required">*</span> 
            </mat-label>
            <input matInput disabled
            id="{{getControlId()}}"
            [errorStateMatcher]="errorState"
            [value]="control.translateItem && (itemLookup | async) !== null ? 
            (control.translatePrefix + '.' + (itemLookup | async) | translate) : (itemLookup | async)">
            <button *ngIf="(itemLookup | async) !== null && control.parent['hasPermissionToChange'] && !isReadOnly()" 
            matSuffix mat-icon-button type="button" aria-label="Clear" class="btn" (click)="clear()">
                <mat-icon style="font-size: 20px;">clear</mat-icon>   
            </button>
            <span class="errorLabel" *ngIf="hasControlError">{{formGroup?.errorMessage}}</span>
        </mat-form-field>
    </div>
</div>
