import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './auth/callback/callback.component';
import { AuthClientGuard } from './auth/auth-client.guard';
import { Module } from './shared/global';
import { AuthEmployeGuard } from './auth/auth-employe.guard';
import { AuthAdminGuard } from './auth/auth-admin.guard';
import { EmployeResolver } from './modules/employe/employe.resolver';
import { ClientResolver } from './modules/client/client.resolver';
import { NotAuthorizedComponent } from './auth/not-authorized/not-authorized.component';

const routes: Routes =
[
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'employe',
    // Lazy load
    loadChildren: () => import('./modules/employe/employe.module').then(mod => mod.EmployeModule),
    data: { module: Module.Employe },
    resolve: {EmployeResolver},
    canActivate: [AuthEmployeGuard]
  },
  {
    path: 'client',
    // Lazy load
    loadChildren: () => import('./modules/client/client.module').then(mod => mod.ClientModule),
    data: { module: Module.Client },
    resolve: {ClientResolver},
    canActivate: [AuthClientGuard]
  },
  {
    path: 'admin',
    // Lazy load
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule),
    data: { module: Module.Admin },
    canActivate: [AuthAdminGuard]
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'notauthorized',
    component: NotAuthorizedComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
