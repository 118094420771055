import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LibAuthService } from 'ngx-tin-auth';
import { Observable, take } from 'rxjs';
import { OdataFilter } from '../../shared/global';
import { NotificationService } from '../../shared/notification/notification.service';
import { BusService } from '../../shared/services/bus.service';
import { UserEmploye } from '../admin/user/model/UserEmploye.model';
import { UserEmployeeService } from './user-employee.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeResolver implements Resolve<boolean>  {
  constructor(
    private userEmployeeService: UserEmployeeService,
    private auth: LibAuthService,
    private serviceBus: BusService,
    private notificationService: NotificationService,
    private traslateService: TranslateService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return new Observable(observer => {
      this.auth.getUserName().then(username => {
        if (username) {
          this.userEmployeeService.get(undefined, undefined, OdataFilter.ByUsername.replaceFilterParams([username]))
            .pipe(take(1))
            .subscribe ((userEmployee: UserEmploye) => {
              if (userEmployee) {
                this.serviceBus.employeeId = userEmployee.employeId;
                this.serviceBus.checkUserPrivateZone = userEmployee.checkUserPrivateZone;
              }
            });
        }
      });
      return this.traslateService.get('global.nom')
        .pipe(take(1))
        .subscribe((res: string) => {
        //Forcer à attendre le chargement des traductions ; Dans certains cas, la grille ne charge pas les titres traduits.
        observer.next(true);
        observer.complete();
      });

    });
  }

}
